<template>
  <v-container>
    <form-paresbiomagneticos :id="id"></form-paresbiomagneticos>
  </v-container>
</template>

<script>
export default {
  components: {
    "form-paresbiomagneticos": () =>
      import("@/components/ParesBiomagneticos/Form"),
  },
  data: () => ({}),
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
};
</script>

<style></style>
